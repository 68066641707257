<template>
  <v-list-item v-if="item">
    <v-list-item-action>
      <v-switch v-model="active" :disabled="!canCollect" :loading="loading" />
    </v-list-item-action>
    <v-list-item-content>
      <v-list-item-title :class="titleColor">
        <v-btn
          color="primary"
          text
          :to="{ name: 'migrations.module', params: { id: item.id } }"
        >
          {{ item.name }}
          <span v-if="tasks" class="ml-2 text-caption">
            <template v-if="totalTasks > tasks">
              ( {{ tasks }} de {{ totalTasks }})
            </template>
            <template v-else>( {{ tasks }} )</template>
          </span>
        </v-btn>
      </v-list-item-title>
      <v-list-item-subtitle>
        <v-progress-linear :color="progressColor" :value="item.progress" />
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action>
      <div>
        <v-btn
          :disabled="!active || !canCollect"
          :loading="showLoading"
          :elevation="0"
          color="primary"
          rounded
          small
          @click="collect"
        >
          <icon-collect class="mr-2" outlined />
          {{ $t("collect.tasks") }}
        </v-btn>

        <v-btn
          :disabled="!active || !canCollect || !item.progress || showLoading"
          :elevation="0"
          class="ml-2"
          color="primary"
          rounded
          small
          @click="restart"
        >
          <icon-refresh-cw class="mr-2" outlined />
          {{ $t("restart.tasks") }}
        </v-btn>

        <v-btn
          :disabled="!active || showLoading"
          :elevation="0"
          class="ml-2"
          color="primary"
          rounded
          small
          @click="toggle('overwrite')"
        >
          <icon-checkbox-unchecked v-if="!overwrite" class="mr-2" outlined />
          <icon-checkbox-checked v-else class="mr-2" outlined />
          {{ $t("overwrite.existing.models") }}
        </v-btn>
      </div>
    </v-list-item-action>
  </v-list-item>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { MigrationModule } from "@planetadeleste/vue-mc-gw";
import IconCollect from "@/components/icons/IconCollect.vue";
import { endsWith, isString, omit } from "lodash";
import type { Channel } from "pusher-js";
import { usePusher } from "@/services/pusher";
import type {
  CreationTasksProgressMigrationChannel,
  ModuleProgressMigrationChannel,
} from "@/types/broadcast";
import type { AxiosRequestConfig } from "axios";
import { route } from "@/services/laroute";
import IconRefreshCw from "@/components/icons/IconRefreshCw.vue";
import { number } from "mathjs";

@Component({
  components: { IconRefreshCw, IconCollect },
})
export default class ModuleListItem extends Vue {
  @Prop(Object) readonly item!: MigrationModule;

  loading = false;

  obModuleProgressChannel: Channel | null = null;
  obCreateTasksProgressChannel: Channel | null = null;
  obPusher = usePusher();

  get active(): boolean {
    return this.item.get("active", false);
  }

  set active(sValue: boolean) {
    this.toggle(sValue, "active");
  }

  get overwrite(): boolean {
    return this.item.get("overwrite", false);
  }

  set overwrite(sValue: boolean) {
    this.toggle(sValue, "overwrite");
  }

  get canCollect(): boolean {
    return !endsWith(this.item.module_type, "CompanyMigrationJob");
  }

  get titleColor(): string {
    return `blue-grey--text text--${this.active ? "darken-1" : "lighten-4"}`;
  }

  get tasks(): number {
    return number(this.item.get("tasks_count", 0));
  }

  get totalTasks(): number {
    return number(this.item.get("total_tasks", 0));
  }

  /**
   * Show loading in create tasks buttons if creation tasks is in progress
   */
  get showLoading(): boolean {
    return this.tasks !== this.totalTasks;
  }

  get progress(): number {
    return this.item ? this.item.get("progress", 0) : 0;
  }

  get progressColor(): "green" | "primary" | string {
    if (!this.active) {
      return "gray";
    }

    return this.progress > 70 ? "green" : "primary";
  }

  /**
   * Setting boolean states of the migration module.
   * @param {boolean|string} sValue
   * @param {string} sKey
   */
  async toggle(sValue: boolean | string, sKey?: string) {
    this.loading = true;

    if (isString(sValue)) {
      sKey = sValue;
      sValue = !this.item.get(sKey, false) as boolean;
    }

    if (!sKey) {
      return;
    }

    this.item.set(sKey, sValue);
    const obModel = new MigrationModule(omit(this.item.attributes, ["_tasks"]));
    await obModel.store();
    this.loading = false;
  }

  /**
   * Collecting the tasks for the migration module.
   */
  async collect() {
    this.loading = true;
    await this.item.collectTasks();
    await this.reload();
    this.loading = false;
  }

  /**
   * Restart tasks to waiting status
   */
  async restart() {
    this.loading = true;
    const obData = { id: this.item.id };
    const obConfig: AxiosRequestConfig = {
      url: route("migrationmodules.restart", obData),
      method: "POST",
      data: obData,
    };
    await this.item.createRequest(obConfig).send();
    await this.reload();
  }

  /**
   * Reload current module
   */
  async reload() {
    this.loading = true;
    const obModel = new MigrationModule({ id: this.item.id });
    await obModel.fetch();
    this.item.set(obModel.attributes);
  }

  onProgress(obData: ModuleProgressMigrationChannel) {
    if (!this.item || obData.module_id !== this.item.id || !obData.progress) {
      return;
    }

    this.item.set("progress", obData.progress);
  }

  onCreateTasksProgress(obData: CreationTasksProgressMigrationChannel) {
    if (!this.item || obData.module_id !== this.item.id || !obData.progress) {
      return;
    }

    this.item.set("tasks_count", obData.tasks_count);
  }

  created() {
    this.obModuleProgressChannel = this.obPusher.channel(
      "migration",
      "module_progress",
      this.onProgress
    );
    this.obCreateTasksProgressChannel = this.obPusher.channel(
      "migration",
      "creation_tasks_progress",
      this.onCreateTasksProgress
    );
  }

  beforeDestroy() {
    if (this.obModuleProgressChannel) {
      this.obModuleProgressChannel.disconnect();
    }
    if (this.obCreateTasksProgressChannel) {
      this.obCreateTasksProgressChannel.disconnect();
    }
  }
}
</script>
